import { titleize } from "src/shared/utils/display-helpers";
import HoldingAccountBadge from "src/admin/liabilities-and-statements/atoms/HoldingAccountBadge";
import React from "react";
import { FlexBox, Pill, TextDense } from "@directsoftware/ui-kit-web-admin";
const renderPayeeRole = (payeeRole) => {
  let role = payeeRole;
  if (!role)
    return "No Role Set";
  if (role === "property_contact") {
    role = "property_owner";
  }
  return titleize(role);
};
const renderHoldingAccount = (trustAccounts, onlyRenderIcon = false) => {
  if (!trustAccounts || trustAccounts.length === 0)
    return onlyRenderIcon ? null : /* @__PURE__ */ React.createElement(Pill, null, "N/A");
  return onlyRenderIcon ? /* @__PURE__ */ React.createElement(HoldingAccountBadge, {
    account: "stripe"
  }) : /* @__PURE__ */ React.createElement(FlexBox, {
    gap: "xs",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(HoldingAccountBadge, {
    account: "stripe"
  }), trustAccounts.map((trust_account) => trust_account.display_name).join("/"));
};
const renderPaymentMethod = (method) => {
  return /* @__PURE__ */ React.createElement(FlexBox, {
    gap: "xs",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(HoldingAccountBadge, {
    account: method
  }), /* @__PURE__ */ React.createElement(TextDense, null, titleize(method)));
};
const renderTableResultsTotal = (count) => {
  return count > 0 ? `${count} Result${count > 1 ? "s" : ""}` : "No Results";
};
export {
  renderPayeeRole,
  renderHoldingAccount,
  renderTableResultsTotal,
  renderPaymentMethod
};
